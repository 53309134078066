import { useEffect } from 'react';
import { pcidAuth } from 'Utils/sso/auth';
import { SSO } from '@/data/constants';
import { useRouter } from 'next/router';
import { getLastViewedPage, saveTokens } from 'Utils/auth';
import { Language } from '@pcid/web-sdk';
import { isPharmaprixBanner } from '@/utils';
import { Spinner } from 'Shared/spinner';
import * as Sentry from '@sentry/react';

export default function LoginRedirect() {
  const router = useRouter();

  useEffect(() => {
    pcidAuth.setLanguage(isPharmaprixBanner() ? Language.FR : Language.EN);
    pcidAuth.setRedirectUrl(`${window.location.origin}/${SSO.LOGIN}`);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get(SSO.CODE) || '';
    const state = urlParams.get(SSO.STATE) || '';

    if (code === '' && state === '') {
      loadSSO();
    } else {
      loadToken(code, state);
    }
  }, []);

  const loadSSO = async () => {
    try {
      window.location.href = await pcidAuth.pcidLoginFunction();
    } catch (err) {
      Sentry.captureMessage('SSO log in failed ' + err);
    }
  };

  const loadToken = async (code: string, state: string) => {
    try {
      const tokens = await pcidAuth.handleSignIn(code, state);

      if (tokens !== null) {
        saveTokens(tokens);
        const lastViewedPage = getLastViewedPage();
        Sentry.captureMessage('SSO log in Successfully ');
        window.location.href = lastViewedPage;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return <Spinner />;
}
